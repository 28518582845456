exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-milk-js": () => import("./../../../src/pages/milk.js" /* webpackChunkName: "component---src-pages-milk-js" */),
  "component---src-pages-mindchat-js": () => import("./../../../src/pages/mindchat.js" /* webpackChunkName: "component---src-pages-mindchat-js" */),
  "component---src-pages-patient-js": () => import("./../../../src/pages/patient.js" /* webpackChunkName: "component---src-pages-patient-js" */),
  "component---src-pages-redesign-js": () => import("./../../../src/pages/redesign.js" /* webpackChunkName: "component---src-pages-redesign-js" */),
  "component---src-pages-roles-js": () => import("./../../../src/pages/roles.js" /* webpackChunkName: "component---src-pages-roles-js" */),
  "component---src-pages-telehealth-js": () => import("./../../../src/pages/telehealth.js" /* webpackChunkName: "component---src-pages-telehealth-js" */),
  "component---src-pages-webapp-js": () => import("./../../../src/pages/webapp.js" /* webpackChunkName: "component---src-pages-webapp-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

